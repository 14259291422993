import React from "react"
import { graphql, Link } from "gatsby"
import get from "lodash.get"
import styled from "styled-components"
import { space } from "styled-system"

import Layout from "../components/layout"
import Grid from "../components/grid"
import SEO from "../components/seo"
import Hero from "../components/page-hero"
import SubNav from "../components/sub-nav"
import Breadcrumbs from "../components/breadcrumbs"
import Slices from "../components/slices"

export const query = graphql`
  query About {
    site {
      siteMetadata {
        title
      }
    }
    prismicAbout {
      data {
        name
        title
        description {
          richText
          text
        }
        sub_pages {
          sub_page {
            document {
              ... on PrismicSubPage {
                id
                uid
                data {
                  name
                }
              }
            }
          }
        }
        image {
          alt
          copyright
          gatsbyImageData(layout: CONSTRAINED, width: 1920)
        }
        body {
          ... on PrismicAboutDataBodyText {
            id
            slice_type
            slice_label
            primary {
              text {
                richText
                text
              }
            }
          }
          ... on PrismicAboutDataBodyImage {
            id
            slice_type
            slice_label
            primary {
              caption {
                richText
                text
              }
              image {
                alt
                copyright
                gatsbyImageData(layout: CONSTRAINED, width: 856)
              }
            }
          }
          ... on PrismicAboutDataBodyGallery {
            id
            slice_type
            slice_label
            items {
              caption {
                richText
                text
              }
              image {
                alt
                copyright
                gatsbyImageData(layout: CONSTRAINED, width: 1024)
              }
            }
          }
        }
      }
    }
  }
`

const Article = styled.article(space)
Article.defaultProps = { mt: ["y.mobile.3.0", "y.desktop.3.0"] }

export default function AboutPage({ location, data }) {
  const page = get(data, `prismicAbout.data`)
  if (!page) return null

  return (
    <Layout location={location}>
      <SEO title={page.name} description={page.description.text} />

      <Grid page>
        <Hero
          title={page.title}
          subtitle={page.description}
          image={page.image}
        />
        <Breadcrumbs>
          <Link to="/">Home</Link>
          <span>{page.name}</span>
        </Breadcrumbs>

        <SubNav path="/about" pages={page.sub_pages} />
        <Slices slices={page.body} page />
      </Grid>
    </Layout>
  )
}
