import styled from "styled-components"
import { compose, grid, space, layout, flexbox } from "styled-system"
import Grid from "./grid"

export const Container = styled.div(compose(grid, space, layout, flexbox), {
  display: "grid",
  position: "relative",
})

Container.defaultProps = {
  mt: ["y.mobile.2.0", "y.desktop.2.0"],
  mb: ["y.mobile.1.0", "y.desktop.1.0"],
  gridColumn: ["full-start / full-end", "main-start / main-end"],
  alignContent: "center",
  minHeight: ["heights.mobile.10.0", "heights.desktop.10.0"],
  gridTemplateColumns: "1fr 1fr",
  gridTemplateAreas: [
    `"left left" "right right"`,
    `"left left" "right right"`,
    `"left right"`,
  ],
  gridColumnGap: ["1.6rem", "2.4rem", "2.4rem"],
}

export const AreaOne = styled(Grid.Item)`
  z-index: 1;

  * {
    color: inherit;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    max-width: 40rem;
  }
`
AreaOne.defaultProps = {
  position: ["absolute", "absolute", "static"],
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  gridArea: ["", "", "left"],
  alignSelf: "center",
  display: ["flex", "flex", "block"],
  flexDirection: "column",
  justifyContent: "center",
  py: ["y.mobile.1.0", "y.desktop.1.0", "y.desktop.0.0"],
}

export const AreaTwo = styled(Grid.Item)``
AreaTwo.defaultProps = {
  gridArea: "right",
}
