import React from "react"
import PropTypes from "prop-types"
import { RichText } from "prismic-reactjs"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { linkResolver } from "../utils/linkResolver"
import htmlSerializer from "../utils/htmlSerializer"
import { Container, AreaOne, AreaTwo } from "./page-hero.styles"
import Text from "./text"

const Hero = ({ title, subtitle, image }) => {
  const imageData = getImage(image)
  return (
    <Container>
      <AreaOne
        color={imageData ? ["white.0", "white.0", "inherit"] : "inherit"}
        px={imageData ? ["x.2", "x.2", "x.0"] : ["x.2", "x.0", "x.0"]}
      >
        <Text.H1 as="h2">{title}</Text.H1>
        {subtitle &&
          RichText.render(subtitle.richText, linkResolver, htmlSerializer)}
      </AreaOne>
      <AreaTwo>
        {imageData && <GatsbyImage image={imageData} alt={image.alt ?? ""} />}
      </AreaTwo>
    </Container>
  )
}

Hero.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.object,
  image: PropTypes.object,
}

export default Hero
